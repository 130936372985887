import Head from 'next/head'
import React, {useContext, useState} from 'react';
import { useMediaQuery } from "react-responsive";
const DesktopLanding = dynamic(() => import('../components/PustackLanding'))
import {PustackProContext} from '../context/global/PustackProContext';
import dynamic from 'next/dynamic';
import useInBrowser from '../hooks/useInBrowser';
import MobileLanding from '../components/MobileLanding';
import {IntroContext} from '../context';
const Dashboard = dynamic(() => import('../components/home/Dashboard'))

export default function Home({proSliderOpen, setProSliderOpen, isSliderOpen, setIsSliderOpen, user, setUser, mobileOpen, setMobileOpen}) {
  // const [isSliderOpen, setIsSliderOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const inBrowser = useInBrowser();
  const [showLandingPage] = useContext(IntroContext).showLandingPage;


  // useEffect(() => {
  //   console.log('user from index - ', !user);
  //   if(!user) initialize();
  // }, [user])

  return (
    // <div className={styles.container}>
    <>
      <Head>
        <title>Pustack</title>
        <meta name="description" content="At PuStack we believe that it is our responsibility to build quality tools and generate flawless content to help students globally." />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/*<main className={styles.main}>*/}
      {/*{!user ? (*/}
      {/*  (inBrowser && isSmallScreen) ? <MobileLanding setUser={setUser} isSliderOpen={isSliderOpen} setIsSliderOpen={setIsSliderOpen} /> : <DesktopLanding user={user} setUser={setUser} isSliderOpen={isSliderOpen} setIsSliderOpen={setIsSliderOpen} />*/}
      {/*) : <Dashboard*/}
      {/*  mobileOpen={mobileOpen}*/}
      {/*  setMobileOpen={setMobileOpen}*/}
      {/*  user={user}*/}
      {/*  setProSliderOpen={setProSliderOpen}*/}
      {/*  proSliderOpen={proSliderOpen}*/}
      {/*/> }*/}

      {
        (!showLandingPage) ? <Dashboard
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          user={user}
        /> : <DesktopLanding user={user} setUser={setUser} isSliderOpen={isSliderOpen} setIsSliderOpen={setIsSliderOpen} />
      }
      {/*<div className="loading__wrapper" data-nosnippet="">*/}
      {/*  <div className="loading__content fadeIn">*/}
      {/*    <Image height={100} width={100}*/}
      {/*      src={logo}*/}
      {/*      alt="Pustack Logo"*/}
      {/*      className="loader__logo"*/}
      {/*    />*/}
      {/*    <span id="loader" />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*</main>*/}

      {/*<footer className={styles.footer}>*/}
      {/*  <a*/}
      {/*    href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    Powered by{' '}*/}
      {/*    <span className={styles.logo}>*/}
      {/*      <Image height={100} width={100} src="/vercel.svg" alt="Vercel Logo" width={72} height={16} />*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*</footer>*/}
    </>
    // </div>
  )
}
